'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="SignIn.tsx">
    <path d="M15.1665 20L19.1665 16L15.1665 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SignIn.tsx" />
    <path d="M11.1667 15.9997H4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SignIn.tsx" />
    <path d="M5.8335 10.6667V9.33333C5.8335 6.38781 8.22131 4 11.1668 4H23.1668C26.1123 4 28.5002 6.38781 28.5002 9.33333V22.6667C28.5002 25.6122 26.1123 28 23.1668 28H11.1668C8.22131 28 5.8335 25.6122 5.8335 22.6667V21.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SignIn.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="SignIn.tsx" />;
};
export default CustomIcon;