'use client';

import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import { useTranslations } from 'next-intl';
import React, { ComponentType } from 'react';
import AuthGuard from '@/src/shared/components/AuthGuard/AuthGuard';
import CharacterCard from '@/src/shared/components/CharacterCard/CharacterCard';
import CharacterCardSkeleton from '@/src/shared/components/CharacterCard/Skeleton/Skeleton';
import CtaCard from '@/src/shared/components/CtaCard/CtaCard';
import EmptyStateCard from '@/src/shared/components/EmptyStateCard/EmptyStateCard';
import SprinklesIcon from '@/src/shared/components/Icons/Sprinkles';
import LoadMoreVisibilityTrigger from '@/src/shared/components/LoadMoreVisibilityTrigger/LoadMoreVisibilityTrigger';
import * as routes from '@/src/shared/constants/routes';
import { useMyCharacters } from '@/src/shared/hooks/queries/useMyCharacters';
import useIsAuthenticated from '@/src/shared/hooks/useIsAuthenticated';
import { getLCM } from '@/src/shared/utilities/helpers/universal/helpers';
const PAGE_SIZE = getLCM({
  multipliers: [2, 3, 4]
});
const MyCharacters: ComponentType = () => {
  const t = useTranslations();
  const isAuthenticated = useIsAuthenticated();
  const {
    flatData: characters = [],
    isLoading,
    hasNextPage,
    fetchNextPage,
    isEmpty
  } = useMyCharacters({
    limit: PAGE_SIZE,
    enabled: isAuthenticated
  });
  return <>
      {characters.map(character => <CharacterCard key={character.id} character={character} />)}

      {(isLoading || hasNextPage) && <CharacterCardSkeleton />}

      {hasNextPage && <LoadMoreVisibilityTrigger onLoadMore={fetchNextPage} />}

      {isEmpty && <>
          <EmptyStateCard icon={<UsergroupAddOutlined />} text={t('home.myCharacters.emptyState.title')} />
          <AuthGuard shouldBeAuthenticated={false}>
            <CtaCard icon={<SprinklesIcon />} text={t('home.myCharacters.unauthorized.cta.title')} cta={{
          text: t('home.myCharacters.unauthorized.cta.button'),
          action: routes.CREATE_CHARACTER
        }} />
          </AuthGuard>
          <AuthGuard>
            <CtaCard icon={<SprinklesIcon />} text={t('home.myCharacters.authorized.cta.title')} cta={{
          text: t('home.myCharacters.authorized.cta.button'),
          action: routes.CREATE_CHARACTER
        }} />
          </AuthGuard>
        </>}
    </>;
};
export default MyCharacters;