'use client';

import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { useTranslations } from 'next-intl';
import React, { ComponentType, useState } from 'react';
interface Props extends Pick<ButtonProps, 'size' | 'type' | 'icon' | 'className'> {
  onClick?: () => void;
}
const RefreshButton: ComponentType<Props> = ({
  size,
  type = 'primary',
  icon = <ReloadOutlined />,
  className,
  onClick
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  return <Button type={type} size={size} icon={icon} onClick={() => {
    if (onClick) {
      onClick();
    } else {
      setIsLoading(true);
      window.location.reload();
    }
  }} loading={isLoading} className={className} data-sentry-element="Button" data-sentry-component="RefreshButton" data-sentry-source-file="RefreshButton.tsx">
      {t('common.refresh')}
    </Button>;
};
export default RefreshButton;