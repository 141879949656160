'use client';

import { useTranslations } from 'next-intl';
import React, { ComponentType } from 'react';
import RefreshButton from '@/src/shared/components/StaticErrorMessage/RefreshButton/RefreshButton';
interface Props {
  className?: string;
}
const StaticErrorMessage: ComponentType<Props> = ({
  className = ''
}) => {
  const t = useTranslations();
  return <div className={`flex items-center justify-center text-center w-full h-full col-span-full p-4 sm:p-6 ${className}`} data-sentry-component="StaticErrorMessage" data-sentry-source-file="StaticErrorMessage.tsx">
      <div className="flex flex-col justify-center items-center border border-red-500/50 bg-red-500/5 p-4 sm:p-6 rounded-2xl">
        <h1 className="text-xl font-bold text-error-500 mb-2">
          {t('staticErrorMessage.title')}
        </h1>
        <p className="text-sm mb-4 max-w-lg">
          {t('staticErrorMessage.description')}
        </p>
        <RefreshButton type="link" className="!bg-red-500 !text-white" data-sentry-element="RefreshButton" data-sentry-source-file="StaticErrorMessage.tsx" />
      </div>
    </div>;
};
export default StaticErrorMessage;