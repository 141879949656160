'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Compass.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.13601 6.13601V6.13601C9.65101 2.62101 15.349 2.62101 18.864 6.13601V6.13601C22.379 9.65101 22.379 15.349 18.864 18.864V18.864C15.349 22.379 9.65101 22.379 6.13601 18.864V18.864C2.62101 15.349 2.62101 9.65101 6.13601 6.13601Z" stroke="currentColor" fill="transparent" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Compass.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.40402 16.007L9.54002 10.325C9.62002 9.929 9.92902 9.62 10.325 9.541L16.007 8.405C16.357 8.335 16.665 8.643 16.595 8.993L15.459 14.675C15.38 15.071 15.07 15.38 14.675 15.459L8.99302 16.595C8.64302 16.666 8.33402 16.357 8.40402 16.007Z" stroke="currentColor" fill="transparent" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Compass.tsx" />
    <path d="M12.499 12.25C12.361 12.25 12.249 12.362 12.25 12.5C12.25 12.638 12.362 12.75 12.5 12.75C12.638 12.75 12.75 12.638 12.75 12.5C12.75 12.362 12.638 12.25 12.499 12.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Compass.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Compass.tsx" />;
};
export default CustomIcon;