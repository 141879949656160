'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Magic.tsx">
    <path d="M16.0434 3.83216V2.29932" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M15.2773 3.06583H16.8102" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.44727 15.8686L3.24015 14.6615C2.93818 14.3596 2.93818 13.8706 3.24015 13.5694L10.5787 6.23087C10.8806 5.9289 11.3696 5.9289 11.6708 6.23087L12.8779 7.43799C13.1799 7.73996 13.1799 8.22894 12.8779 8.53014L5.53942 15.8686C5.23822 16.1699 4.74847 16.1699 4.44727 15.8686Z" stroke="currentColor" strokeWidth="1.38913" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M11.0694 10.2012L8.90039 8.03223" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M16.8102 11.4965H15.2773" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M16.0434 10.73V12.2628" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M7.61368 2.29932V3.83216" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
    <path d="M8.37855 3.06583H6.8457" stroke="currentColor" strokeWidth="1.37956" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Magic.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Magic.tsx" />;
};
export default CustomIcon;