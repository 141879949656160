'use client';

import { Segmented, SegmentedProps } from 'antd';
import React, { JSX, ComponentType, useMemo, useState } from 'react';
import useIsHydrated from '@/src/shared/hooks/useIsHydrated';
export type SegmentedTabType = {
  key: string;
  label: string | JSX.Element;
  icon?: JSX.Element;
  content?: JSX.Element;
};
interface Props {
  tabs: SegmentedTabType[];
  segmentedProps?: Pick<SegmentedProps<string>, 'rootClassName' | 'block' | 'size' | 'vertical'>;
  rootClassName?: string;
}
const SegmentedTabs: ComponentType<Props> = ({
  tabs,
  rootClassName,
  segmentedProps = {}
}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(tabs[0].key);
  const isHydrated = useIsHydrated();
  const tabOptions = useMemo(() => {
    return tabs.map(({
      key,
      label,
      icon
    }) => ({
      value: key,
      label: label,
      icon: icon
    }));
  }, [tabs]);
  const activeTabContent = useMemo(() => {
    const activeTab = tabs.find(tab => tab.key === activeTabKey);
    return activeTab?.content || null;
  }, [activeTabKey, tabs]);
  return <div className={rootClassName} data-sentry-component="SegmentedTabs" data-sentry-source-file="SegmentedTabs.tsx">
      <Segmented {...segmentedProps} options={tabOptions} value={activeTabKey} onChange={value => {
      if (!isHydrated) return;
      setActiveTabKey(value);
    }} data-sentry-element="Segmented" data-sentry-source-file="SegmentedTabs.tsx" />
      {activeTabContent}
    </div>;
};
export default SegmentedTabs;